<template>
  <b-card :title="$t('booking.guide.payment') + paymentMethodName" class="checkout--card">
    <div class="text-center">
      <div class="pb3">
        <p>{{ $t('booking.paymentValue') }}</p>
        <h3 class="bold mt1" id="finalPrice">
          {{ $n(price, 'currency', 'en-SG') }}
        </h3>
        <a
          class="text-helper"
          id="copyToClipboard"
          v-clipboard:copy="price"
          v-clipboard:success="handleCopyStatus(true)"
          v-clipboard:error="handleCopyStatus(false)"
          href="javascript:"
        >
          {{ $t('booking.copyValue') }}
        </a>
        <b-popover :show="copySucceeded" target="copyToClipboard" title="">
          {{ $t('general.success') }} <strong> {{ $t('general.clipboard') }}</strong>
        </b-popover>
      </div>

      <div class="pt3 pb-3">
        <p>{{ $t('booking.no') }} {{ paymentMethodName }}</p>
        <h3 class="bold mt1">{{ virtualAccountNumber }}</h3>
        <a
          class="text-helper"
          id="copyToClipboardVa"
          v-clipboard:copy="virtualAccountNumber"
          v-clipboard:success="handleCopyVaStatus(true)"
          v-clipboard:error="handleCopyVaStatus(false)"
          href="javascript:"
        >
          {{ $t('booking.copyBank') }}
        </a>
        <b-popover :show="copyVaSucceeded" target="copyToClipboardVa" title="">
          {{ $t('general.success') }} <strong> {{ $t('general.clipboard') }}</strong>
        </b-popover>
      </div>
      <div class="pt3">
        <p>{{ $t('booking.orderNo') }}</p>
        <h3 class="bold primary mt1">{{ orderNumber }}</h3>
      </div>
    </div>
  </b-card>
</template>

<script>
export default {
  name: 'panelDescription',
  // props: ['bookingOrder'],
  props: ['paymentMethodName', 'price', 'virtualAccountNumber', 'viewMybookingUrl', 'orderNumber'],
  data: () => ({
    copySucceeded: false,
    copyVaSucceeded: false,
  }),
  watch: {
    copySucceeded(val) {
      if (val) {
        setTimeout(() => {
          this.copySucceeded = false;
        }, 4000);
      }
    },
    copyVaSucceeded(val) {
      if (val) {
        setTimeout(() => {
          this.copyVaSucceeded = false;
        }, 4000);
      }
    },
  },
  methods: {
    handleCopyStatus(status) {
      this.copySucceeded = status;
    },
    handleCopyVaStatus(status) {
      this.copyVaSucceeded = status;
    },
  },
};
</script>

<style scoped></style>
